import { useState } from "react";
import { useRef } from "react";

const projects = [
  {
    name: "What do users think of our product?",
    initials: "SL",
    href: "https://rhetor.ai/f_98D6EP",
    product_name: "Slack",
    bgColor: "rgb(74, 21, 75)",
  },
  {
    name: "Who uses our product?",
    initials: "N",
    href: "https://rhetor.ai/m7edRhhk",
    product_name: "Notion",
    bgColor: "rgb(29, 28, 29)",
  },

  {
    name: "How do users feel about our paid plans?",
    initials: "C",
    href: "https://rhetor.ai/0V7IdQAH",
    product_name: "Canva",
    bgColor: "rgb(32, 196, 203)",
  },
  {
    name: "How do we compare with other dating apps?",
    initials: "T",
    href: "https://rhetor.ai/qcmnMpbh",
    product_name: "Tinder",
    bgColor: "rgb(254, 60, 114)",
  },
];

export default function DashboardSection() {
  const [iframeUrl, setIframeUrl] = useState<string>(projects[0].href);
  const iframeRef = useRef<HTMLDivElement>(null);

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <p className="text-lg font-semibold leading-8 tracking-tight text-sky-600">
            How does it work?
          </p>
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Try Our Demos Here
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Test price sensitivity · Understand your main benefit · Get design
            feedback · Discover unexpected use cases
            <br />
            and more...
            <br />
          </p>
        </div>
      </div>

      <div className="max-w-7xl px-6 lg:px-8 mx-auto my-4">
        <ul
          role="list"
          className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {projects.map((project) => (
            <button
              key={project.name}
              onClick={() => {
                setIframeUrl(project.href);
                if (iframeRef.current) {
                  iframeRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
              className={`text-left ${
                iframeUrl === project.href &&
                "shadow-2xl scale-[101%] transition"
              }`}
              style={
                {
                  "--tw-shadow-color": project.bgColor.slice(0, -1) + ", 0.3)",
                  "--tw-shadow": "var(--tw-shadow-colored)",
                } as React.CSSProperties
              }>
              <li className="col-span-1 flex rounded-md shadow-sm">
                <div
                  className="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                  style={{
                    backgroundColor: project.bgColor,
                  }}>
                  {project.initials}
                </div>
                <div
                  className="h-20 flex flex-1 items-center justify-between rounded-r-md border-t border-r border-b bg-white"
                  style={{
                    borderColor:
                      iframeUrl === project.href ? project.bgColor : "#e5e7eb",
                  }}>
                  <div className="flex-1 px-4 py-2 text-sm h-18">
                    <p className="font-medium text-gray-900 hover:text-gray-600">
                      {project.name}
                    </p>
                    <p className="text-gray-500">{project.product_name}</p>
                  </div>
                </div>
              </li>
            </button>
          ))}
        </ul>
      </div>

      <div className="relative overflow-hidden pt-8" ref={iframeRef}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="w-full h-[35rem] sm:h-[44rem] border border-gray-300 rounded-xl overflow-hidden">
            <iframe
              src={iframeUrl}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
