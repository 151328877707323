import { GetStaticPropsResult } from "next";
import { getActiveProductsWithPrices } from "../../utils/supabase-client";
import { ProductWithPrice } from "../../types/types";
import useUserSession from "../../hooks/useUserSession";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { useState } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingCard() {
  const { isSignedIn } = useUserSession();
  const router = useRouter();
  const [currentTier, setCurrentTier] = useState<string>("monthly");
  const tiers = [
    {
      id: "tier-basic",
      name: "Basic (For individuals)",
      priceMonthly: 0,
      priceAnnually: 0,
      description:
        "Great for getting started. Create your first two researches for free.",
      features: [
        "1 project",
        "10 responses / project",
        "Customize AI name and role",
        "No credit card required",
      ],
    },
    {
      id: "tier-plus",
      name: "Plus (For teams)",
      priceMonthly: 9,
      priceAnnually: 5,
      description:
        "Need higher quotas? Upgrade and create up to 10 researches.",
      features: [
        "10 projects",
        "100 responses / project",
        "Export your responses",
        "Shared team logins",
        // "Ask AI (coming soon)",
      ],
    },
    {
      id: "tier-custom",
      name: "Custom (For enterprises)",
      priceMonthly: 1000,
      priceAnnually: 10000,
      description:
        "Are you a business looking for higher quotas or custom features? We can work with you to develop a solution.",
      features: [
        "Custom usage quotas",
        "Custom-tuned AI model",
        "Third-party integrations",
        "Bespoke success manager & priority support",
      ],
    },
  ];
  const [enabled, setEnabled] = useState(true);
  return (
    <div className="flow-root bg-white mt-20 sm:mt-40" id="pricing">
      <div className="relative mx-auto max-w-7xl px-6 text-center lg:px-8 mb-10">
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <h2 className="text-lg font-semibold leading-8 text-sky-600">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900">
            Find the right plan for your project
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 mb-6">
        <div className="mt-16 flex justify-center">
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(
                enabled ? "bg-sky-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
              )}>
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 text-sm">
              <span className="font-medium text-gray-900">Annual billing</span>{" "}
              <span className="text-gray-500">(Save 45%)</span>
            </Switch.Label>
          </Switch.Group>
        </div>
      </div>
      <div className="">
        <div className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-7xl lg:grid-cols-3 lg:gap-8">
            {tiers.map((tier) => (
              <div
                key={tier.name}
                className="flex flex-col rounded-3xl bg-white shadow-xl ring-1 ring-black/10">
                <div className="p-8 sm:p-10">
                  <div className="flex">
                    <h3
                      className="text-lg font-semibold leading-8 tracking-tight text-sky-600"
                      id={tier.id}>
                      {tier.name}
                    </h3>
                    <div
                      className={classNames(
                        enabled ? "" : "hidden",
                        "items-center ml-2"
                      )}>
                      {tier.id === "tier-plus" && (
                        <p className="rounded-full bg-sky-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-sky-600">
                          45% OFF
                        </p>
                      )}
                    </div>
                  </div>
                  {enabled ? (
                    <div className="mt-4 flex items-baseline text-5xl tracking-tight text-gray-900 font-semibold">
                      {tier.id === "tier-custom"
                        ? "Contact us"
                        : "$" + tier.priceAnnually}
                      <span className="text-lg font-semibold leading-8 tracking-normal text-gray-500">
                        {tier.id === "tier-plus" ? "/month" : ""}
                      </span>
                    </div>
                  ) : (
                    <div className="mt-4 flex items-baseline text-5xl tracking-tight text-gray-900 font-semibold">
                      {tier.id === "tier-custom"
                        ? "Contact us"
                        : "$" + tier.priceMonthly}
                      <span className="text-lg font-semibold leading-8 tracking-normal text-gray-500">
                        {tier.id === "tier-plus" ? "/month" : ""}
                      </span>
                    </div>
                  )}

                  <p className="mt-6 text-base leading-7 text-gray-600">
                    {tier.description}
                  </p>
                </div>
                <div className="flex flex-1 flex-col p-2">
                  <div className="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 p-6 sm:p-8">
                    <ul role="list" className="space-y-6">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon
                              className="h-6 w-6 text-sky-600"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-sm leading-6 text-gray-600">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-8">
                      <a
                        onClick={
                          tier.id === "tier-custom"
                            ? () => router.push("mailto:mail@rhetorai.com")
                            : !isSignedIn
                            ? () => router.push("/signin")
                            : () => router.push("/dashboard/account")
                        }
                        className="inline-block w-full rounded-lg bg-sky-600 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-sky-700 cursor-pointer"
                        aria-describedby={tier.id}>
                        {tier.id === "tier-custom"
                          ? "Contact us"
                          : !isSignedIn
                          ? "Get started today"
                          : "Manage your plans"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
