import { useRouter } from "next/router";

export default function CTASection() {
  const router = useRouter();

  return (
    <div className="bg-sky-700">
      <div className="py-24 px-6 sm:px-6 sm:py-28 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-lg font-semibold leading-8 text-sky-200">
            Privacy
          </h2>
          <h2 className="mt-3 text-4xl font-bold tracking-tight text-white">
            We don&apos;t use your data to train our AI.
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-sky-200">
            We have no interest in your business data. Your customer transcripts
            will not be used to train our AI.
          </p>
          {/* <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              onClick={() => router.push("/signin")}
              className="rounded-md bg-white px-3.5 py-1.5 text-base font-semibold leading-7 text-sky-600 shadow-sm hover:bg-sky-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white cursor-pointer"
            >
              Create your first interview link in 5 mins{" "}
              <span className="text-sky-600" aria-hidden="true">
                &rarr;
              </span>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://cal.com/ronaldyao/30min"
              className="inline-block rounded-lg bg-sky-600 border border-sky-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-sky-600 hover:bg-sky-700 hover:ring-sky-700 cursor-pointer"
            >
              Book a demo
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
