import Head from "next/head";
import { useRouter } from "next/router";
import PublicSiteHeader from "../components/PublicSiteHeader";
import HeroSection from "../components/HeroSection";
import ProductSection from "../components/ProductSection";
import DashboardSection from "../components/DashboardSection";
import PricingCard from "../components/PricingSection/PricingSection";
import CTASection from "../components/CTASection";
import FAQSection from "../components/FAQSection";
import Footer from "../components/Footer";
import FeatureSection from "../components/FeatureSection";
import DiscountBanner from "../components/DiscountBanner";

export default function Home() {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>RhetorAI</title>
        <meta
          name="description"
          content="Conversational user surveys powered by AI.
                    Automatically ask deep follow-up questions based on user's feedback.
                    Stop settling for shallow insights, sign up for free."
        />
      </Head>
      {/* <DiscountBanner /> */}
      <PublicSiteHeader />
      <HeroSection />
      <ProductSection />
      <FeatureSection />
      <DashboardSection />
      <CTASection />
      <PricingCard />
      <FAQSection />
      <Footer />
    </>
  );
}
