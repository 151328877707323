import EmailSVG from "./emailSVG";
import InsightsSVG from "./InsightsSVG";
import LanguageSVG from "./languageSVG";
import QuestionNumberSVG from "./questionNumberSVG";
import FollowUpSVG from "./followUpSVG";
import EmbedSVG from "./embedSVG";
const features = [
  {
    header: "Automated Follow-ups",
    description:
      "Our AI automatically follows up on user responses, diving deep to reveal their genuine thoughts about your product.",
    image: <FollowUpSVG />,
  },
  {
    header: "Insights Generation",
    description:
      "Our AI reviews and analyzes user conversations, saving you time and effort, and providing you with profound insights.",
    image: <InsightsSVG />,
  },
  {
    header: "Multilingual",
    description:
      "Our AI communicates fluently in different languages, adapting to your user's language preference effortlessly.",
    image: <LanguageSVG />,
  },

  {
    header: "Flexible Conversation Length",
    description:
      "Customize the conversation length - gather in-depth feedback or keep it brief and user-friendly.",
    image: <QuestionNumberSVG />,
  },
  {
    header: "Website Embed",
    description:
      "Embed to your website with just one line of code, allowing users to share their thoughts without leaving your site.",
    image: <EmbedSVG />,
  },
  {
    header: "Email Notifications",
    description:
      "Get email alerts whenever there's a new response, saving you the hassle of daily dashboard checks for updates.",
    image: <EmailSVG />,
  },
];

export default function Example_old() {
  return (
    <div className="overflow-hidden bg-white sm:py-24">
      {/* <div className="mx-auto max-w-2xl sm:text-center mb-16">
        <p className="text-lg font-semibold leading-8 tracking-tight text-sky-600">
          Go Beyond the Constraints of User Surveys
        </p>
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Features You Would Love
        </h2>
      </div> */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {features.map((feature, i) => (
          <div
            className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-4 lg:gap-y-16  lg:mx-0 lg:max-w-none lg:grid-cols-2 mb-12 lg:mb-16 items-center "
            key={i}
          >
            <div className="lg:ml-auto align-center">
              <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {feature.header}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600 mb-4">
                {feature.description}
              </p>
            </div>
            <div className="flex items-start justify-center lg:order-first">
              {feature.image}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
