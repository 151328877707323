import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
  ArrowTrendingUpIcon,
  ChatBubbleLeftEllipsisIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/20/solid";
import Image from "next/image";

const features = [
  {
    name: "100+ feedbacks/week.",
    description: "",
    icon: ArrowTrendingUpIcon,
  },
  {
    name: "Customer-driven decisions.",
    description: "",
    icon: ChatBubbleLeftEllipsisIcon,
  },
  {
    name: "Transcripts, stored forever.",
    description: "",
    icon: ArchiveBoxIcon,
  },
];

export default function ProductSection() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 lg:px-0 lg:pt-24 lg:pr-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-lg font-semibold leading-8 tracking-tight text-sky-600">
                What is RhetorAI?
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                It&apos;s a feedback collection AI for your business.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                <span className="font-bold">
                  We use ChatGPT to interview your customers.
                </span>{" "}
                Just tell us a bit about your business and what you&apos;d like
                to learn from your customers, and we&apos;ll create a custom
                chatbot for you. You can then share the link with your
                customers, and they&apos;ll be interviewed by our AI.
              </p>
              <dl className="pl-2 mt-10 max-w-xl space-y-4 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute top-1 left-1 h-5 w-5 text-sky-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="relative isolate overflow-hidden bg-sky-600 mx-auto max-w-2xl sm:rounded-3xl sm:pt-14 sm:pl-12 lg:mx-0 lg:max-w-none">
              <div
                className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-sky-100 opacity-20 ring-1 ring-inset ring-white"
                aria-hidden="true"
              />
              <div className="mx-0">
                <Image
                  src="/interview-chat.png"
                  alt="Product screenshot"
                  width={1179}
                  height={1440}
                  priority={true}
                  className="-mb-12 w-[36rem] h-[46rem] pt-4 max-w-none rounded-t-xl rounded-b-xl bg-white ring-1 ring-white/10 object-cover object-left-top scale-[80%] translate-x-[-5rem] translate-y-[0.2rem] sm:translate-x-0 sm:translate-y-0 sm:scale-100 sm:h-auto"
                />
              </div>
              <div
                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
